<template>
  <div
    class="p-2"
  >
    <a
      v-if="pages.length > 1"
      href="#"
      @click.prevent="toggleMosaicNavigation"
    >
      <img
        :src="btn.mosaic"
        alt="Navegación por miniaturas"
      >
    </a>
    <a
      v-if="!isFirst"
      href="#"
      @click.prevent="goFirstPage"
    >
      <img
        :src="btn.first"
        alt="Ir a la primera página"
      >
    </a>
    <a
      v-if="hasAudio"
      href="#"
      @click.prevent="togglePlayPauseAudio"
    >
      <img
        v-if="audioStatus === 'stop' || audioStatus === 'pause'"
        :src="btn.play"
        alt="Reproducir audio"
      >
      <img
        v-if="audioStatus === 'play'"
        :src="btn.pause"
        alt="Pausar audio"
      >
    </a>
    <a
      v-if="hasLink"
      href="#"
      @click.prevent="openLink"
    >
      <img
        :src="btn.link"
        alt="Abrir enlace"
      >
    </a>
    <a
      v-if="hasVideo"
      href="#"
      @click.prevent="openVideo"
    >
      <img
        :src="btn.video"
        alt="Abrir vídeo"
      >
    </a>
  </div>
</template>

<script>
import LinkBtn from "@/assets/img/btn/link-btn.png";
import VideoBtn from "@/assets/img/btn/video-btn.png";
import FirstBtn from "@/assets/img/btn/first-btn.png";
import MosaicNavBtn from "@/assets/img/btn/mosaic-nav-btn.png";
import PlayBtn from "@/assets/img/controls/play.png";
import PauseBtn from "@/assets/img/controls/pause.png";

export default {
  name: "PresentationRightSidebar",
  props: {
    index: {
      type: Number,
      default: 0
    },
    pages: {
      type: Array,
      default: () => {
        return []
      }
    },
    audioStatus: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      btn: {
        link: LinkBtn,
        video: VideoBtn,
        first: FirstBtn,
        mosaic: MosaicNavBtn,
        play: PlayBtn,
        pause: PauseBtn
      },
    }
  },
  computed: {
    data() {
      if (!this.pages) {
        return null
      }

      return this.pages[this.index]
    },
    isFirst() {
      return this.index === 0
    },
    hasLink() {
      return !!this.data?.link
    },
    hasVideo() {
      return !!this.data?.video
    },
    INTERNAL_URLS() {
      return this.$store.state.internalUrls
    },
    hasAudio() {
      return !!this.data?.audio
    }
  },
  methods: {
    openLink() {
      const url = this.data?.link ? this.data.link.replace('http:', 'https:') : null
      if (!this.isInternalURL(url)) {

        this.$buefy.dialog.confirm({
          message: 'Estás a punto de abrir la dirección <b>' + url + '</b>',
          cancelText: 'Cancelar',
          confirmText: 'Continuar',
          onConfirm: () => {
            window.open(url)
          }
        })
      } else {
        window.open(url)
      }
    },
    openVideo() {
      this.$emit('open-video', {id: this.data.video})
    },
    goFirstPage() {
      this.$emit('go-to', {type: 'next', index: 0})
    },
    isInternalURL(url) {
      const match = this.INTERNAL_URLS.find((internalURL) => url.indexOf(internalURL) === 0)

      return !!match;
    },
    toggleMosaicNavigation() {
      this.$emit('toggle-mosaic')
    },
    togglePlayPauseAudio() {
      this.$emit('toggle-audio')
    }
  }
}
</script>

<style scoped>

</style>
