<template>
  <div v-if="itemId">
    <media-viewer
      ref="presentationViewer"
      v-touch:swipe.right="swipeHandler"
      v-touch:swipe.left="swipeHandler"
      :custom-class="viewerClasses"
      :data="data"
      :scene-index="sceneIndex"
      @error="loadingError"
      @audio-status-changed="audioStatusChanged"
    />
    <mosaic-navigation
      ref="mosaicNavigation"
      :opened="mosaicNavigationOpened"
      :pages="itemScenes"
      :width="containerWidth"
      :height="containerFullHeight"
      @change="goToPage"
    />
    <right-side-menu
      v-if="showRightSideMenu"
      v-click-outside="closeRightSidebar"
      :opened="rightSideOpen"
      @open="rightSideOpenChange"
    >
      <presentation-right-sidebar
        :pages="itemScenes"
        :index="sceneIndex"
        :audio-status="audioStatus"
        @go-to="goToPage"
        @open-video="videoModalOpen"
        @toggle-mosaic="toggleMosaicNavigationOpened"
        @toggle-audio="toggleAudioStatus"
      />
    </right-side-menu>
    <video-modal
      v-model="videoModal.videoId"
      :show="videoModal.show"
      @close="videoModalClose"
    />
    <pager-bar
      ref="pagerBar"
      :data="itemScenes"
      :selected="sceneIndex"
      @change="goToPage"
    />
  </div>
</template>

<script>
import ImageUtils from "@/utils/image";
import MediaViewer from "@/components/medias/MediaViewer";
import MosaicNavigation from "@/components/MosaicNavigation";
import RightSideMenu from "@/components/RightSideMenu";
import PresentationRightSidebar from "@/components/sidebars/PresentationRightSidebar";
import VideoModal from "@/components/modals/VideoModal";
import PagerBar from "@/components/PagerBar";

export default {
  name: 'PresentationDetail',
  components: {
    PagerBar,
    VideoModal,
    PresentationRightSidebar,
    RightSideMenu,
    MosaicNavigation,
    MediaViewer
  },
  title: 'Presentación',
  data: function () {
    return {
      itemId: null,
      data: null,
      hasErrors: false,
      section_title: 'Presentación',
      sceneIndex: 0,
      containerWidth: '100%',
      containerFullHeight: '100%',
      containerHeight: '100%',
      mosaicNavigationOpened: false,
      rightSideOpen: false,
      audioStatus: null,
      videoModal: {
        show: false,
        videoId: ''
      },
    }
  },
  computed: {
    userToken() {
      return this.$store.state.token
    },
    itemScenes() {
      if (!this.data) {
        return []
      }

      return this.data.sheets
    },
    showRightSideMenu() {
      if (!this.data || this.data.sheets.length === 0) {
        return false
      }
      if (this.data.sheets.length > 1) {
        return true
      }
      const firstSheet = this.data.sheets[0]

      return !!firstSheet.audio || !!firstSheet.link || !!firstSheet.video
    },
    viewerClasses() {
      if (this.itemScenes && this.itemScenes.length > 1) {
        return 'with-pager'
      }

      return null
    },
  },
  watch: {
    userToken() {
      this.loadContent()
    },
    mosaicNavigationOpened(newVal){
      if(!newVal){
        this.$store.commit('setRouterInterceptorFnc', null)
      }

      if(newVal){
        this.$store.commit('setRouterInterceptorFnc', this.closeMosaicNavigationModal.bind(this))
      }
    },
    sceneIndex(){
      this.updateAudioStatus()
    }
  },
  beforeDestroy() {
    this.$store.commit('setRouterInterceptorFnc', null)
  },
  beforeMount() {
    this.itemId = this.$router.currentRoute.params.id

    if (this.$router.currentRoute.hash.indexOf('#') === 0) {
      this.sceneIndex = parseInt(this.$router.currentRoute.hash.replace('#', ''))

      if (Number.isNaN(this.sceneIndex)) {
        this.sceneIndex = 0
      }
    }
  },
  mounted() {
    if (this.itemId) {
      this.loadContent()
    }
    this.refreshItemSizes()
    window.addEventListener('resize', this.refreshItemSizes)
  },
  destroyed() {
    window.removeEventListener('resize', this.refreshItemSizes)
  },
  methods: {
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    async loadContent() {
      try {
        console.info("carga datos presentación")
        await this.$store.dispatch('initLoading')

        this.data = await this.$api.presentation.getById(this.itemId)
        this.updateAudioStatus()
        this.setPageTitle(this.data.title)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    goToPage(event) {
      if (event.type === 'prev' && event.index === (this.itemScenes.length - 1)) {
        //avoid paginate to last element from first
        return
      }
      this.mosaicNavigationOpened = false
      this.sceneIndex = event.index
    },
    rightSideOpenChange(open) {
      this.rightSideOpen = open

      this.refreshItemSizes()
    },
    async refreshItemSizes() {
      await this.$nextTick()
      this.containerWidth = this.rightSideOpen ? (document.body.clientWidth - 80) + 'px' : '100%'
      //this.containerFullHeight = this.$refs.container.clientHeight + 'px'
      const fixedBarsHeight = 101
      //let itemHeight = this.$refs.container.clientHeight - fixedBarsHeight
      /*if (this.data?.sheets && this.data.sheets.length <= 1) {
        itemHeight += 60
      }
      itemHeight += 'px'
      this.containerHeight = itemHeight*/
    },
    swipeHandler(side) {
      console.warn(side)
      let index = side === 'left' ? this.sceneIndex + 1 : this.sceneIndex - 1
      let type = side === 'left' ? 'next' : 'prev'
      if (index < 0) {
        index = 0
      }
      if (index >= this.itemScenes.length) {
        index = 0
      }

      this.goToPage({index, type})
    },
    toggleMosaicNavigationOpened() {
      this.mosaicNavigationOpened = !this.mosaicNavigationOpened
    },
    loadingError() {
      this.hasErrors = true
    },
    videoModalOpen(video) {
      if (!video.id) {
        return
      }
      this.videoModal.show = true
      this.videoModal.videoId = video.id
    },
    videoModalClose() {
      this.videoModal.show = false
      this.videoModal.videoId = ''
    },
    closeRightSidebar() {
      this.rightSideOpen = false
    },
    closeMosaicNavigationModal(){
      this.mosaicNavigationOpened = false
    },
    audioStatusChanged(event){
      this.audioStatus = event.status
    },
    updateAudioStatus(){
      const page = this.itemScenes[this.sceneIndex]
      this.audioStatus = !!page.audio?'pause':null
    },
    toggleAudioStatus(){
      this.$refs.presentationViewer.toggleAudio()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
