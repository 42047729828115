import { render, staticRenderFns } from "./PresentationRightSidebar.vue?vue&type=template&id=a798a006&scoped=true&"
import script from "./PresentationRightSidebar.vue?vue&type=script&lang=js&"
export * from "./PresentationRightSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a798a006",
  null
  
)

export default component.exports